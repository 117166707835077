import { path } from 'ramda';
import { getId } from '#/object-id';
import parachute from '~/utils/parachute';
import { fetchAll } from '~/utils/crud-functions';
import CreditYearMixin from '~/mixins/creditYear';
export default {
    name: 'training-statistics',
    mixins: [
        CreditYearMixin,
    ],
    components: {},
    model: {
        prop: 'training',
    },
    props: {
        training: {
            type: Object,
        },
        participantsByType: {
            type: Object
        }
    },
    data() {
        return {
            allCounts: {},
        };
    },
    watch: {
        selectedCreditYear: {
            handler() {
                this.resetStats();
                this.updateStats();
            },
            immediate: true,
        },
    },
    computed: {
        participantCount() {
            var _a, _b, _c;
            return (_c = (_b = this.participantsByType[(_a = this.training) === null || _a === void 0 ? void 0 : _a.creditType]) === null || _b === void 0 ? void 0 : _b.total) !== null && _c !== void 0 ? _c : 0;
        },
        sessionCount() {
            return path(['session', 'count'], this.allCounts);
        },
        serviceCount() {
            return path(['service', 'count'], this.allCounts);
        },
    },
    methods: {
        path,
        resetStats() {
            this.allCounts = 0;
        },
        updateStats() {
            this.count();
        },
        count: parachute(async function countItems(target) {
            const results = await fetchAll(`participations/count/${target !== null && target !== void 0 ? target : ''}`, {
                filters: {
                    training: getId(this.training),
                    range: this.selectedCreditYearRange,
                },
            });
            this.allCounts = results;
        }),
    },
};
