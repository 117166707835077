import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { domains } from '#/domains';
import { creditTypes } from '#/credit';
import { trainingTypes } from '#/training-type';
import ItemSearchSelector from '~/components/item-search-selector.vue';
export default {
    name: 'training-fields',
    components: {
        ValidationObserver,
        ValidationProvider,
        ItemSearchSelector
    },
    props: {
        training: {
            type: Object,
        },
    },
    model: {
        prop: 'training',
    },
    data() {
        return {
            domains,
            creditTypes,
            trainingTypes,
            restrictDomainChanges: Boolean(this.training.domain)
        };
    },
};
