var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.training)?_c('div',{staticClass:"training-fields"},[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validate = ref.validate;
return [_c('v-row',{staticClass:"pb-2"},[_c('v-col',{attrs:{"cols":"6","md":"6"}},[_c('ValidationProvider',{ref:"name",attrs:{"name":"name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"name":"name","label":_vm.$t('trainings.name'),"error-messages":errors,"outlined":""},on:{"input":validate},model:{value:(_vm.training.name),callback:function ($$v) {_vm.$set(_vm.training, "name", $$v)},expression:"training.name"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"6","md":"6"}},[_c('ValidationProvider',{ref:"hours",attrs:{"name":"hours","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"number","name":"hours","label":_vm.$t('trainings.hours'),"error-messages":errors,"outlined":""},on:{"input":validate},model:{value:(_vm.training.hours),callback:function ($$v) {_vm.$set(_vm.training, "hours", $$v)},expression:"training.hours"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"6","md":"6"}},[_c('ValidationProvider',{ref:"organizer",attrs:{"name":"organizer","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('item-search-selector',{attrs:{"name":"organizer","resources":['agencies'],"label":_vm.$tc('agencies.title', 1),"item-text":function (item) { return item.name; },"error-messages":errors,"hide-details":"","no-filter":"","clearable":"","outlined":""},on:{"input":function($event){return validate()}},model:{value:(_vm.training.organizer),callback:function ($$v) {_vm.$set(_vm.training, "organizer", $$v)},expression:"training.organizer"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"6","md":"6"}},[_c('ValidationProvider',{ref:"reference",attrs:{"name":"reference"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"name":"reference","label":_vm.$t('trainings.reference'),"placeholder":_vm.$t('trainings.idText'),"error-messages":errors,"outlined":"","readonly":""},on:{"input":validate},model:{value:(_vm.training.reference),callback:function ($$v) {_vm.$set(_vm.training, "reference", $$v)},expression:"training.reference"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('ValidationProvider',{ref:"trainingType",attrs:{"name":"trainingType"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"name":"trainingType","items":_vm.trainingTypes,"label":_vm.$t('trainings.trainingType'),"error-messages":errors,"outlined":""},on:{"input":validate},model:{value:(_vm.training.type),callback:function ($$v) {_vm.$set(_vm.training, "type", $$v)},expression:"training.type"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('ValidationProvider',{ref:"description",attrs:{"name":"description"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{attrs:{"name":"description","label":_vm.$t('trainings.description'),"error-messages":errors,"outlined":""},on:{"input":validate},model:{value:(_vm.training.description),callback:function ($$v) {_vm.$set(_vm.training, "description", $$v)},expression:"training.description"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"6","md":"6"}},[_c('ValidationProvider',{ref:"domain",attrs:{"name":"domain","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"name":"domain","items":_vm.domains,"label":_vm.$t('trainings.domain'),"error-messages":errors,"outlined":"","readonly":_vm.restrictDomainChanges},on:{"input":validate},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$t(("domains." + item)))+" ")]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$t(("domains." + item)))+" ")]}}],null,true),model:{value:(_vm.training.domain),callback:function ($$v) {_vm.$set(_vm.training, "domain", $$v)},expression:"training.domain"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"6","md":"6"}},[_c('ValidationProvider',{ref:"creditType",attrs:{"name":"creditType","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"name":"creditType","items":_vm.creditTypes,"label":_vm.$t('trainings.creditType'),"error-messages":errors,"outlined":""},on:{"input":validate},model:{value:(_vm.training.creditType),callback:function ($$v) {_vm.$set(_vm.training, "creditType", $$v)},expression:"training.creditType"}})]}}],null,true)})],1)],1)]}}],null,false,2834002261)})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }