import λ from '~/utils/invoker';
import { endOfYear, isWithinInterval, startOfYear } from 'date-fns';
import { parseDate } from '#/date-fns-utils';
import { CreditYears } from '#/credit';
import { head, last } from 'ramda';
export default {
    computed: {
        selectedCreditYear: λ.vuexState('year'),
        selectedCreditYearRange() {
            const base = new Date(this.selectedCreditYear, 0, 1);
            return {
                start: startOfYear(base),
                end: endOfYear(base),
            };
        },
        allowedCreditYearRange() {
            const range = CreditYears.sort();
            return {
                start: startOfYear(new Date(head(range), 0, 1)),
                end: endOfYear(new Date(last(range), 0, 1)),
            };
        },
    },
    methods: {
        isWithinSelectedCreditYearRange(date) {
            return isWithinInterval(parseDate(date), this.selectedCreditYearRange);
        },
        isWithinAllowedCreditYearRange(date) {
            return isWithinInterval(parseDate(date), this.allowedCreditYearRange);
        },
    },
};
