import λ from '~/utils/invoker';
import { getId } from '#/object-id';
import { routeNames } from '~/router';
import FormMixin from '~/mixins/form';
import GoBackWrapper from '~/components/go-back-wrapper.vue';
import PermissionMixin from '~/mixins/permission';
import TrainingFields from '~/trainings/trainings-fields.vue';
import TrainingStatistics from '~/trainings/training-statistics.vue';
import { ValidationObserver } from 'vee-validate';
import CreditForItemMixin from '~/mixins/creditForItem';
import { mdiContentSaveOutline, mdiPlusCircleOutline, mdiViewList } from '@mdi/js';
const trainingsλ = λ.namespaced('trainings');
export default {
    name: 'trainings-form',
    mixins: [FormMixin('trainings'), CreditForItemMixin('training'), PermissionMixin],
    components: {
        ValidationObserver,
        GoBackWrapper,
        TrainingFields,
        TrainingStatistics,
    },
    props: {
        id: {
            type: String,
        },
    },
    data() {
        return {
            mdiContentSaveOutline,
            mdiPlusCircleOutline,
            mdiViewList,
            routeNames,
        };
    },
    async created() {
        await this.prepareForm(this.id);
    },
    computed: {
        item: trainingsλ.state('editing'),
        canList: λ.self('canListTrainings'),
        hasId() {
            return Boolean(getId(this.item));
        },
        canSave() {
            return this.canUpdateTrainings;
        },
        canAddParticipation() {
            return this.canCreateParticipations;
        },
    },
    methods: {
        addNewParticipation() {
            this.$router.push({
                name: routeNames.participations.create,
                query: { training: getId(this.item) },
                params: { goBackTo: { name: routeNames.trainings.edit, params: { id: getId(this.item) } } },
            });
        },
        goToParticipationsList() {
            this.$router.push({
                name: routeNames.participations.list,
                query: { training: getId(this.item) },
                params: { goBackTo: { name: routeNames.trainings.edit, params: { id: getId(this.item) } } },
            });
        },
    },
};
